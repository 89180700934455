/* eslint-disable import/no-extraneous-dependencies */

/* Libraries */

/* Core */
import 'core/controllers'
import { TeePublic } from 'core/globals'
import RudderstackHelpers from 'core/services/rudderstack_helpers';
import initProductClicks from 'core/services/rudderstack'

/* Shared */
import 'shared/autocomplete'
import EmailHelpers from 'shared/helpers/email_helpers'
import Validators from 'shared/validators'
import initUpdateSaleTimes from 'shared/legacy_js/home'
import mtrlModals from 'shared/modals/mtrl_modals'
import initExploreNav from 'shared/navigation/explore_nav'

TeePublic.EmailHelpers = EmailHelpers;
TeePublic.Validators = Validators;
TeePublic.RudderstackHelpers = RudderstackHelpers;
TeePublic.initProductClicks = initProductClicks;

mtrlModals.mtrlModal();

initExploreNav();
initUpdateSaleTimes();
